<template>
  <v-app>
    <Navbar v-if="this.$route.path !== '/login'" />
    <v-main>
      <router-view> </router-view>
      <v-snackbar 
      v-model="snackbarVisible" 
      :timeout="snackbarTimeout"
      :color="snackbarColor"
      right
      >
      <v-layout align-center mr-20>
        <v-icon class="pr-3" dark large>{{ snackbarIcon }}</v-icon>
        <v-layout column>
          <div>
            <strong>{{ snackbarTitle }}</strong>
          </div>
          <div>{{ snackbarMessage }}</div>
        </v-layout>

      <v-btn icon @click="snackbarVisible = false">
        <v-icon>clear</v-icon>
      </v-btn>
      </v-layout>
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import Navbar from "@/components/Navbar";

export default {
  name: "App",
  data: () => ({
    visible: false,
    temp: null,
  }),
  components: {
    Navbar,
  },
  computed: {
    snackbarVisible: {
      get: function () {
        return this.$store.state.snackbar.visible;
      },
      set: function (value){
        this.$store.state.snackbar.visible = value;
      }
    },
    snackbarMessage() {
      return this.$store.state.snackbar.message;
    },
    snackbarTimeout() {
      return this.$store.state.snackbar.timeout;
    },
    snackbarColor() {
      return this.$store.state.snackbar.color;
    },
    snackbarIcon() {
      return this.$store.state.snackbar.icon;
    },
    snackbarTitle() {
      return this.$store.state.snackbar.title;
    },
  },
  watch: {
    snackbarVisible(newVar, oldVar){
    }
  },
  created() {
    console.log("#### start load");
    var isLogedIn = this.$api.isLogedIn();
    console.log("#### load completed");
    if (!isLogedIn && this.$route.path != "/login") {
      this.$router.push({ name: "Login" });
    }
    this.initialize();
  },
  methods: {
    initialize(){
      this.$api.emitter.on('success', message => {
        this.$store.dispatch('notifyMessage', {type: 'success', message})
      });
      this.$api.emitter.on('error', message => {
        this.$store.dispatch('notifyMessage', {type: 'error', message})
      });
      this.$api.emitter.on('info', message => {
        this.$store.dispatch('notifyMessage', {type: 'info', message})
      });
      this.$api.emitter.on('warning', message => {
        console.log("2423rpoq wejufoiasofujopa;wujfoqwu3jp9f2qu3pofu2;oif;o");
        this.$store.dispatch('notifyMessage', {type: 'warning', message})
      });
    },
    closeSnackbar() {
      this.$store.state.snackbar.visible = false;
    },
  },
};
</script>
