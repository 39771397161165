import Vue from "vue";
import VueGapi from "vue-gapi";

const clientConfig = {
    apiKey:'AIzaSyC9FGvz1eDHuO22AxQ2gBzWW2ytUHo8u30',
    clientId: '854336023050-k477c6rrffjfkv6seekfdt45lb19f4sk.apps.googleusercontent.com',
    discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'],
    scope: 'https://www.googleapis.com/auth/drive'
  }

Vue.use(VueGapi, clientConfig);