<template>
  <nav>
    <v-navigation-drawer v-model="drawer" dark="" app style="background: #0072BC">
      <v-layout column align-center>
        <v-flex class="mt-4 mb-2">
          <v-img
            :aspect-ratio="1280 / 356"
            width="150"
            src="proudia.png"
          ></v-img>
        </v-flex>
      </v-layout>
      <v-list>
        <v-list-item
          v-for="link in links"
          :key="link.text"
          router
          :to="link.route"
          active-class="border"
        >
          <v-list-item-action>
            <v-icon>{{ link.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ link.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app elevation="2">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <!-- Breadcrumbs -->
      <v-toolbar-title>
        <!-- <v-breadcrumbs :items="titleItems" :large="true">
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs> -->
        <v-breadcrumbs :items="titleItems">
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
              {{ item.text.toUpperCase() }}
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn class="mr-2 red--text" @click="logout" elevation="0">Logout</v-btn>
      <v-btn class="mr-2 red--text" @click="notifySuccess()" elevation="0">Send</v-btn>
    </v-app-bar>
  </nav>
</template>

<script>
export default {
  data: () => ({
    drawer: true,
    links: [
      { icon: "fas fa-home", text: "Overview", route: "/" },
      {
        icon: "fas fa-sign-in-alt",
        text: "Projects",
        route: "/projects",
      },
      {
        icon: "fas fa-dice-d6",
        text: "API Test",
        route: "/api-test",
      },
    ],
  }),
  computed: {
    titleItems() {
      var items = [];
      var path = "";
      var params = this.$route.path.split("/");
      for (var i in params) {
        var param = params[i];
        if (!param) continue;
        path += `/${param}`;
        items.push({
          text: param.charAt(0).toUpperCase() + param.slice(1),
          disabled: i == params.length - 1,
          href: path,
        });
      }
      return items;
    }
  },
  methods: {
    logout() {
      this.$api.logout();
      this.$router.push({ path: "/login" });
    },
    log() {
      console.log(this.$api.userdata);
    },
    notifySuccess(){
      this.$store.dispatch('notifyMessage', {type: 'info', message: 'message'})
    }
  },
};
</script>


<style scoped>
.border {
  border-left: 4px solid #2c3e50;
}
</style>
