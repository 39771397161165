"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileType = exports.LinkType = exports.TargetBuild = exports.Platform = void 0;
var Platform;
(function (Platform) {
    Platform[Platform["iOS"] = 0] = "iOS";
    Platform[Platform["Android"] = 1] = "Android";
    Platform[Platform["Standalone"] = 2] = "Standalone";
})(Platform = exports.Platform || (exports.Platform = {}));
var TargetBuild;
(function (TargetBuild) {
    TargetBuild[TargetBuild["Development"] = 0] = "Development";
    TargetBuild[TargetBuild["Production"] = 1] = "Production";
})(TargetBuild = exports.TargetBuild || (exports.TargetBuild = {}));
var LinkType;
(function (LinkType) {
    LinkType[LinkType["Private"] = 0] = "Private";
    LinkType[LinkType["Public"] = 1] = "Public";
})(LinkType = exports.LinkType || (exports.LinkType = {}));
var FileType;
(function (FileType) {
    FileType[FileType["None"] = 0] = "None";
    FileType[FileType["Folder"] = 1] = "Folder";
    FileType[FileType["Image"] = 2] = "Image";
    FileType[FileType["Video"] = 3] = "Video";
    FileType[FileType["Audio"] = 4] = "Audio";
    FileType[FileType["Execute"] = 5] = "Execute";
    FileType[FileType["IPA"] = 6] = "IPA";
    FileType[FileType["APK"] = 7] = "APK";
    FileType[FileType["Text"] = 8] = "Text";
})(FileType = exports.FileType || (exports.FileType = {}));
