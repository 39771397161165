<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.background }">
    <v-container fluid fill-width>
      <v-layout row class="ma-8" child-flex>
        <!-- <div class="text-h4 primary--text">Project > TEST</div> -->
        <div class="d-flex flex-column mb-6">
          <!-- <v-row class="d-flex justify-space-between mb-6">
            <v-card
              class="d-flex justify-space-between"
              :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-4'"
              flat
              tile
              ><v-card-title primary-title class="blue--text">
                  <v-btn elevation="0">Projects</v-btn>
              </v-card-title></v-card
            >
            <v-btn>test</v-btn>
          </v-row> -->
          <v-card outlined class="pa-2" elevation="1">
            <v-data-table
              :headers="headers"
              :items="desserts"
              sort-by="calories"
              class="elevation-0"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <!-- <v-toolbar-title>Projects</v-toolbar-title> -->
                  <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
                  <v-spacer></v-spacer>
                  <!-- New Project Dialog -->
                  <v-dialog
                    v-model="dialog"
                    width="600px"
                    :persistent="isDisable"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        style="background: #0072bc"
                        dark
                        class="mb-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        New Project
                      </v-btn>
                    </template>
                    <v-card :disabled="isDisable">
                      <v-card-title>
                        <span class="headline">{{ formTitle }}</span>
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col>
                              <!-- Code -->
                              <v-row align="center" justify="center">
                                <v-col cols="auto" class="mr-auto py-1"
                                  >Code:
                                </v-col>
                                <v-col class="py-1">
                                  <v-text-field
                                    v-model="editedItem.code"
                                    :disabled="editedIndex != -1"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <!-- Name English -->
                              <v-row align="center" justify="center">
                                <v-col cols="auto" class="mr-auto py-1"
                                  >Name English:
                                </v-col>
                                <v-col class="py-1">
                                  <v-text-field
                                    v-model="editedItem.name_en"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <!-- Name Japance -->
                              <v-row align="center" justify="center">
                                <v-col cols="auto" class="mr-auto py-1"
                                  >Name Japance:
                                </v-col>
                                <v-col class="py-1">
                                  <v-text-field
                                    v-model="editedItem.name_jp"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <!-- Path -->
                              <v-row align="center" justify="center">
                                <v-col cols="auto" class="mr-auto py-1"
                                  >Path:
                                </v-col>
                                <v-col class="py-1">
                                  <v-text-field
                                    v-model="editedItem.sub_path"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <!-- Platform -->
                              <v-row align="center" justify="center">
                                <v-col cols="auto" class="mr-auto"
                                  >Platform:
                                </v-col>
                                <v-col>
                                  <v-chip-group
                                    v-model="editedItem.platforms_id"
                                    column
                                    multiple
                                  >
                                    <v-chip
                                      v-for="(name, index) in platformsName"
                                      :key="index"
                                      filter
                                      outlined
                                      :color="
                                        getPlatformColor(getPlatformID(name))
                                      "
                                      >{{ name }}</v-chip
                                    >
                                  </v-chip-group>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="close">
                          Cancel
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="create"
                          :disabled="createCondition"
                        >
                          {{ formSubmit }}
                        </v-btn>
                      </v-card-actions>
                      <v-progress-linear
                        color="primary"
                        indeterminate
                        rounded
                        height="6"
                        v-if="isDisable"
                      ></v-progress-linear>
                    </v-card>
                  </v-dialog>
                  <!-- Add Existing Project Dialog -->
                  <v-dialog
                    v-model="dialogAddExisting"
                    width="600px"
                    :persistent="isDisable"
                    class="ml-2"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        style="background: #0072bc"
                        dark
                        class="mb-2 ml-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        Add Existing Project
                      </v-btn>
                    </template>
                    <v-card :disabled="isDisable">
                      <v-card-title>
                        <span class="headline">Add Existing Project</span>
                      </v-card-title>

                      <v-card-text>
                        * insert the same `code`, `name_en`, `name_jp` names on
                        Google Drive.
                        <v-container>
                          <v-row>
                            <v-col>
                              <!-- Code -->
                              <v-row align="center" justify="center">
                                <v-col cols="auto" class="mr-auto py-1"
                                  >Code:
                                </v-col>
                                <v-col class="py-1">
                                  <v-text-field
                                    v-model="editedItem.code"
                                    :disabled="editedIndex != -1"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <!-- Name English -->
                              <v-row align="center" justify="center">
                                <v-col cols="auto" class="mr-auto py-1"
                                  >Name English:
                                </v-col>
                                <v-col class="py-1">
                                  <v-text-field
                                    v-model="editedItem.name_en"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <!-- Name Japance -->
                              <v-row align="center" justify="center">
                                <v-col cols="auto" class="mr-auto py-1"
                                  >Name Japance:
                                </v-col>
                                <v-col class="py-1">
                                  <v-text-field
                                    v-model="editedItem.name_jp"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <!-- Path -->
                              <v-row align="center" justify="center">
                                <v-col cols="auto" class="mr-auto py-1"
                                  >Path:
                                </v-col>
                                <v-col class="py-1">
                                  <v-text-field
                                    v-model="editedItem.sub_path"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <!-- Platform -->
                              <v-row align="center" justify="center">
                                <v-col cols="auto" class="mr-auto"
                                  >Platform:
                                </v-col>
                                <v-col>
                                  <v-chip-group
                                    v-model="editedItem.platforms_id"
                                    column
                                    multiple
                                  >
                                    <v-chip
                                      v-for="(name, index) in platformsName"
                                      :key="index"
                                      filter
                                      outlined
                                      :color="
                                        getPlatformColor(getPlatformID(name))
                                      "
                                      >{{ name }}</v-chip
                                    >
                                  </v-chip-group>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="close">
                          Cancel
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="addProject"
                          :disabled="createCondition"
                        >
                         Add 
                        </v-btn>
                      </v-card-actions>
                      <v-progress-linear
                        color="primary"
                        indeterminate
                        rounded
                        height="6"
                        v-if="isDisable"
                      ></v-progress-linear>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                      <v-card-title class="headline"
                        >Are you sure you want to delete this
                        item?</v-card-title
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDelete"
                          >Cancel</v-btn
                        >
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="deleteItemConfirm"
                          >OK</v-btn
                        >
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:[`item.platforms_id`]="{ item }">
                <v-chip
                  class="mx-1 white--text"
                  v-for="(id, index) in item.platforms_id"
                  :key="index"
                  close-icon="mdi-close-outline"
                  :color="getPlatformColor(id)"
                  small
                  >{{ getPlatformName(id) }}
                </v-chip>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                  small
                  depressed
                  class="mr-2"
                  router
                  :to="'projects/' + item.code"
                  >Project</v-btn
                >
                <v-btn
                  small
                  depressed
                  class="mr-2"
                  router
                  :href="getFolderLink(item.file_id)"
                  target="_blank"
                  >Drive</v-btn
                >
                <v-btn small depressed class="mr-2" @click="editItem(item)"
                  >Edit</v-btn
                >
              </template>
              <template v-slot:no-data>
                <v-progress-circular
                  indeterminate
                  color="primary"
                  v-if="isLoading"
                ></v-progress-circular>
              </template>
            </v-data-table>
          </v-card>
        </div>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
import Vue from "vue";
import { Platform } from "../api/entities.js";
import { APIManager } from "../api/APIManager.js";

export default {
  data: () => ({
    isLoading: true,
    isDisable: false,
    platformsActive: [0],
    platformsName: [
      Platform[Platform.iOS],
      Platform[Platform.Android],
      Platform[Platform.Standalone],
    ],
    dialog: false,
    dialogAddExisting: false,
    dialogDelete: false,
    headers: [
      { text: "Code", value: "code" },
      { text: "Name", value: "name" },
      { text: "Sub Path", value: "sub_path", sortable: false },
      { text: "Platforms", value: "platforms_id", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      code: "",
      name: "",
      name_en: "",
      name_jp: "",
      sub_path: "",
      platforms_id: [],
      platforms: [],
      key: "",
      file_id: "",
    },
    defaultItem: {
      code: "",
      name: "",
      name_en: "",
      name_jp: "",
      sub_path: "",
      platforms_id: [],
      platforms: [],
      key: "",
      file_id: "",
    },
  }),

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogAddExisting(val){
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  computed: {
    projectName() {
      return `${this.name_jp}${this.name_jp ? " - " : ""}${this.name_en}`;
    },
    formSubmit() {
      return this.editedIndex === -1 ? "Create" : "Edit";
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Project" : "Edit Project";
    },
    createCondition() {
      return this.editedItem.code &&
        this.editedItem.name_en &&
        this.editedItem.platforms_id.length > 0
        ? false
        : true;
    },
  },

  methods: {
    async initialize() {
      this.desserts = await this.$api.getProjectsTable();
      this.isLoading = false;
    },
    openProject(item) {
      this.router.push({ path: `${item.code}` });
    },
    async editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.dialogAddExisting = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async create() {
      // set name
      console.log(this.editedItem);
      this.editedItem.name = `${
        this.editedItem.name_jp ? `${this.editedItem.name_jp} - ` : ""
      }${this.editedItem.name_en}`;

      // sort platforms_id
      this.editedItem.platforms_id.sort();
      var createProject = {
        code: this.editedItem.code,
        name_en: this.editedItem.name_en,
        name_jp: this.editedItem.name_jp,
        platforms: this.editedItem.platforms_id,
        sub_path: this.editedItem.sub_path,
        file_id: "",
      };
      if (this.editedIndex > -1) {
        this.isDisable = true;
        await this.$api.editProject(createProject);
        this.isDisable = false;
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.isDisable = true;
        var project = await this.$api.createProject(createProject);
        this.editedItem.file_id = project.projectFolderId;
        this.isDisable = false;

        this.desserts.push(this.editedItem);
      }
      this.close();
    },
    async addProject(){
      // set name
      console.log(this.editedItem);
      this.editedItem.name = `${
        this.editedItem.name_jp ? `${this.editedItem.name_jp} - ` : ""
      }${this.editedItem.name_en}`;

      // sort platforms_id
      this.editedItem.platforms_id.sort();
      var project = {
        code: this.editedItem.code,
        name_en: this.editedItem.name_en,
        name_jp: this.editedItem.name_jp,
        platforms: this.editedItem.platforms_id,
        sub_path: this.editedItem.sub_path,
        file_id: "",
      };
      if (this.editedIndex > -1) {
        this.isDisable = true;
        await this.$api.editProject(project);
        this.isDisable = false;
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.isDisable = true;
        var project = await this.$api.addProject(project);
        this.editedItem.file_id = project.projectFolderId;
        this.isDisable = false;

        this.desserts.push(this.editedItem);
      }
      this.close();
    },
    getPlatformColor(id) {
      switch (id) {
        case Platform.iOS:
          return "primary";
        case Platform.Android:
          return "green";
        case Platform.Standalone:
          return "orange";
        default:
          return "#a6a6a6";
      }
    },
    getPlatformName(id) {
      return Platform[id];
    },
    getPlatformID(name) {
      return Platform[name];
    },
    getPlatformsFromIDs(ids) {
      var platforms = [];
      for (var i in ids) {
        var id = ids[i];
        var name = Platform[id];
        platforms.push(name);
      }
      return platforms;
    },
    getFolderLink(file_id) {
      return this.$api.getFolderLink(file_id);
    },
  },
};
</script>
