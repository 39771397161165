var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',{staticClass:"ma-4"},[_c('v-row',[_c('v-col',{staticClass:"align-self-center",attrs:{"cols":"auto"}},[_vm._v("path:")]),_c('v-col',[_c('v-text-field',{staticClass:"pa-0",model:{value:(_vm.current_path),callback:function ($$v) {_vm.current_path=$$v},expression:"current_path"}},[_vm._v("/")])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticStyle:{"background":"#0072bc"},attrs:{"dark":""},on:{"click":function($event){return _vm.reload()}}},[_vm._v(" Reload ")])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticStyle:{"background":"#0072bc"},attrs:{"dark":""}},[_vm._v(" Test ")])],1)],1),_c('v-row',[_c('v-col',[(_vm.isFetching)?_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"align":"center","indeterminate":"","color":"primary"}})],1):_c('v-treeview',{attrs:{"active":_vm.active,"items":_vm.items,"open":_vm.open,"hoverable":"","transition":""},on:{"update:active":function($event){_vm.active=$event},"update:open":function($event){_vm.open=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{domProps:{"textContent":_vm._s(("" + (_vm.iconFromType(item.type))))}})]}},{key:"label",fn:function(ref){
var item = ref.item;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',[_c('span',{staticClass:"grabbing noselect mr-8"},[_vm._v(_vm._s(item.name))]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(hover)?_c('v-item',[_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Left ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Left tooltip")])]),(hover && item.type == 1)?_c('v-btn',{staticClass:"mr-4",staticStyle:{"background":"#0072bc"},attrs:{"elevation":"0","fab":"","x-small":"","dark":""}},[_c('v-icon',[_vm._v("mdi-folder-plus")])],1):_vm._e(),(hover && item.type == 1)?_c('v-btn',{staticClass:"mr-4",staticStyle:{"background":"#0072bc"},attrs:{"elevation":"0","fab":"","x-small":"","dark":""}},[_c('v-icon',[_vm._v("mdi-file-plus")])],1):_vm._e(),(hover)?_c('v-btn',{staticClass:"mr-4",staticStyle:{"background":"#0072bc"},attrs:{"elevation":"0","fab":"","x-small":"","dark":""}},[_c('v-icon',[_vm._v("mdi-content-copy")])],1):_vm._e(),(hover)?_c('v-btn',{staticClass:"mr-4",staticStyle:{"background":"#0072bc"},attrs:{"elevation":"0","fab":"","x-small":"","dark":""}},[_c('v-icon',[_vm._v("mdi-google-drive")])],1):_vm._e()],1)]}}],null,true)})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }