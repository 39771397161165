<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.background }">
    <v-container v-for="item in items" v-bind:key="item.id">
      <v-layout row class="ma-4">
        <v-card outlined class="pa-2">
          <v-card-title>{{item.name}}</v-card-title>
          <v-divider class="mx-4"></v-divider>
          <v-card-actions v-if="item.hasA">
            <v-text-field label="a" outlined dense v-model="item.valueA"></v-text-field>
          </v-card-actions>
          
          <v-card-actions v-if="item.hasB">
            <v-text-field label="b" outlined dense v-model="item.valueB"></v-text-field>
          </v-card-actions>

          <v-card-text >
            {{item.result}}
          </v-card-text>
          <v-card-actions>
          <v-progress-linear v-if="item.loading"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </v-card-actions>
          <v-card-actions>
            <v-btn depressed color="primary" text @click="execute(item)">
              Execute
            </v-btn>
            <v-btn depressed color="error" text @click="reset(item)"> Reset </v-btn>
          </v-card-actions>
        </v-card>
      </v-layout>
    </v-container>
      <v-container>
                  <input type="file" id="file_input" @change="previewFiles" webkitdirectory/>

    </v-container>

  </v-app>
</template>

<script>

import { testFunction, testFunctionWithA, testFunctionWithAAndB ,uploadFile} from "@/api/test-api.js"
import { uploadFile2 } from '../api/test-api';
export default {
  data() {
    return {
        items: [
            {name: "testFunction", hasA: false, hasB: false, valueA: undefined, valueB: undefined, loading: false, target: testFunction, result: undefined},
            {name: "testFunctionWithA", hasA: true, hasB: false, valueA: undefined, valueB: undefined, loading: false, target: testFunctionWithA, result: undefined},
            {name: "testFunctionWithAAndB", hasA: true, hasB: true, valueA: undefined, valueB: undefined, loading: false, target: testFunctionWithAAndB, result: undefined},
        ],
        testItem:"rrrr"
    }
  },
  name: "api-test",
  components: {},
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
  methods: {
      async execute(item){
          item.loading = true;
          item.result = await item.target(item.valueA, item.valueB);
          item.loading = false;
      },
      reset(item){
          item.result = undefined;
      },
     previewFiles(event) {
          const name = event.target.files[0].name;
          const type = event.target.files[0].type;
          // let reader = new FileReader()
          //   reader.onload = function (e) {      
          //    // uploadFile(name,type,e.target.result)

          //   }
          //   reader.readAsArrayBuffer(event.target.files[0]);

          uploadFile2(event.target.files)

      }

     


  }
};
</script>

<style scoped>
.rounded {
  border-radius: 30px;
}
.ligne {
  border-right: solid 1px #95a5a6;
}

.ligneitem {
  border-bottom: solid 1px #95a5a6;
}
</style>