<template>
  <v-flex class="ma-4">
    <v-row>
      <v-col cols="auto" class="align-self-center">path:</v-col>
      <v-col>
        <v-text-field v-model="current_path" class="pa-0">/</v-text-field>
      </v-col>
      <v-col cols="auto">
        <v-btn style="background: #0072bc" dark @click="reload()">
          Reload
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn style="background: #0072bc" dark> Test </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="text-center" v-if="isFetching">
          <v-progress-circular
            align="center"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
        <v-treeview
          v-else
          :active.sync="active"
          :items="items"
          :open.sync="open"
          hoverable
          transition
        >
          <template v-slot:prepend="{ item }">
            <v-icon v-text="`${iconFromType(item.type)}`"></v-icon>
          </template>
          <template v-slot:label="{ item }">
            <v-hover v-slot:default="{ hover }">
              <div>
                <span class="grabbing noselect mr-8">{{ item.name }}</span>
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-item v-if="hover">
                      <v-btn color="primary" dark v-bind="attrs" v-on="on">
                        Left
                      </v-btn>
                    </v-item>
                  </template>
                  <span>Left tooltip</span>
                </v-tooltip>
                <!-- Create Folder -->
                <v-btn
                  style="background: #0072bc"
                  v-if="hover && item.type == 1"
                  elevation="0"
                  class="mr-4"
                  fab
                  x-small
                  dark
                  ><v-icon>mdi-folder-plus</v-icon></v-btn
                >
                <!-- Upload File -->
                <v-btn
                  style="background: #0072bc"
                  v-if="hover && item.type == 1"
                  elevation="0"
                  class="mr-4"
                  fab
                  x-small
                  dark
                  ><v-icon>mdi-file-plus</v-icon></v-btn
                >
                <!-- Copy Link -->
                <v-btn
                  style="background: #0072bc"
                  v-if="hover"
                  elevation="0"
                  class="mr-4"
                  fab
                  x-small
                  dark
                  ><v-icon>mdi-content-copy</v-icon></v-btn
                >
                <!-- Open Drive -->
                <v-btn
                  style="background: #0072bc"
                  v-if="hover"
                  elevation="0"
                  class="mr-4"
                  fab
                  x-small
                  dark
                  ><v-icon>mdi-google-drive</v-icon></v-btn
                >
              </div>
            </v-hover>
          </template>
        </v-treeview>
      </v-col>
    </v-row>
  </v-flex>
</template>

<script>
import { FileType } from "../../api/entities.js";

export default {
  name: "project_tap_general",
  data: () => ({
    current_path: "/",
    default_path: "/",
    active: [],
    open: [],
    isFetching: false,
    items: [],
    open: [1, 2],
    search: null,
    caseSensitive: false,
  }),
  created() {
    this.reload();
  },
  computed: {
    filter() {
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined;
    },
  },
  methods: {
    iconFromType(fileType) {
      switch (fileType) {
        case FileType.Folder:
          return "mdi-folder";
        case FileType.Image:
          return "mdi-file-image";
        case FileType.Video:
          return "mdi-file-video";
        case FileType.Audio:
          return "mdi-file-music";
        case FileType.Execute:
          return "mdi-microsoft-windows";
        case FileType.IPA:
          return "mdi-apple-ios";
        case FileType.APK:
          return "mdi-android";
        default:
          return "mdi-file";
      }
    },
    async reload() {
      this.isFetching = true;
      this.items = [];
      await new Promise((resolve) => setTimeout(resolve, 1500));
      this.items = await this.test();
      this.isFetching = false;
    },
    async test() {
      return await [
        {
          id: 1,
          name: "Test Folder",
          type: FileType.Folder,
          children: [
            {
              id: 2,
              name: "General",
              type: FileType.Folder,
              children: [
                {
                  id: 3,
                  type: FileType.None,
                  name: "unknown",
                },
              ],
            },
            {
              id: 10,
              type: FileType.Folder,
              name: "Media",
              children: [
                {
                  id: 4,
                  type: FileType.Image,
                  name: "File 2",
                },
                {
                  id: 5,
                  type: FileType.Video,
                  name: "File 3",
                },
                {
                  id: 6,
                  type: FileType.Audio,
                  name: "File 4",
                },
              ],
            },
            {
              id: 11,
              type: FileType.Folder,
              name: "Build",
              children: [
                {
                  id: 7,
                  type: FileType.Execute,
                  name: "File 5",
                },
                {
                  id: 8,
                  type: FileType.IPA,
                  name: "File 6",
                },
                {
                  id: 9,
                  type: FileType.APK,
                  name: "File 7",
                },
              ],
            },
          ],
        },
      ];
    },
  },
};
</script>


<style scoped>
.grabbing {
  cursor: default;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>