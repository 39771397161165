import main from '../main.js'
import AuthManager from './authmanager.js';
import FileManager from './filemanager.js';
import { DatabaseManager } from './databasemanager.js';
import ProjectManager, { Build, BuildPlatform, BuildType, PermissionRole, PermissionType } from './projectmanager.js';
import FileSaver from 'file-saver'


import * as zip from '@zip.js/zip.js/dist/zip';






export const uploadFile = async function (name, type, data) {
    // Add testing


    //   var accessToken = await AuthManager.getAccesToken();

    //   var projectFolder = await FileManager.getFolder("Projects","",accessToken);

    //    var upload = await FileManager.uploadFile(name,type,data,projectFolder.id,accessToken)
    //     console.log(upload);



    //  var build =   await ProjectManager.createBuild(data,"test.png",BuildType.Development,BuildPlatform.Standalone,"0.1","1",type,true,"TEST1");
    //  console.log("completed");

    // console.log(build);


    var source = await ProjectManager.createSource(data, name, "0.11", "1", type, true, "TEST1");

    console.log(source);

    return "testFunction completed";
}


export const uploadFile2 = async function (files) {
   
   
   // - create the inputBlob object storing the data to compress
// use a BlobWriter to store with a ZipWriter the zip into a Blob object
const blobWriter = new zip.BlobWriter("application/zip");
const writer = new zip.ZipWriter(blobWriter);

// use a TextReader to read the String to add
for (let i = 0; i < files.length; i++) {
    const file = files[i];

    await writer.add(file.webkitRelativePath, new zip.BlobReader(file));

}

// close the ZipReader
await writer.close();

// get the zip file as a Blob
const blob = await blobWriter.getData();

FileSaver.saveAs(blob,"test.zip")
  // data:application/zip;base64,UEsDBBQACAgIAJuFO1IAAAAAAAAAAAAAAAAIAAAAdGVzdC50e...
  
  // - create a Data64URIReader object with the zipped content 
  // - create a ZipReader object to read the zipped data
//   const zipReader = new zip.ZipReader(new zip.Data64URIReader(dataURI));
  
//   // - get entries from the zip file
//   const entries = await zipReader.getEntries();
  
//   // - use a TextWriter object to write the unzipped data of the first entry into a string
//   const data = await entries[0].getData(new zip.TextWriter());
//   // - close the ZipReader object
//   await zipReader.close();
  
//   // - log data
//   console.log(data);
  // Lorem ipsum dolor sit amet, consectetuer adipiscing elit...



    // var res =   await ProjectManager.createSourceWithFolder(files,"test","0.1","1",true,"TEST5");

    // console.log(res);

    // create a BlobReader to read with a ZipReader the zip from a Blob object
    // var blob = files[0];

    // let reader = new FileReader();

    // reader.readAsArrayBuffer(blob);

    // reader.onload = function (e) {
    //     var data = reader.result;

    //     // creating archives
    //     var zip = new AdmZip();

    //     // add file directly
    //     var content = "inner content of the file";
    //     //zip.addFile("test.txt", Buffer.alloc(content.length, content), "entry comment goes here");
    //     // add local file
    //     new Response(blob).arrayBuffer().then((data) => {
    //         zip.addFile(blob.name, data);
    //         // get everything as a buffer
    //         zip.toBuffer((buffer) => {
    //             var b = new Blob([buffer]);
    //             FileSaver.saveAs(b, "test.zip")
    //         })
    //     });



    // };



}



export const testFunction = async function () {
    // Add testing

    const delay = ms => new Promise(resolve => setTimeout(resolve, ms));
    await delay(1000);

    return "testFunction completed";
}




export const testFunctionWithA = async function (a) {
    // Add testing
    // main.vueInstance.$gap




    // await Projectmanager.createProject("eee","eee","testsu2",[0,2],"DRAFT");

    var token = await AuthManager.getAccesToken();

    console.log(token);

    //   var e= await ProjectManager.addProject("TEST1","TEST_EN","TEST_JP",[0,1],"WOW/Test");
    // console.log(e);


    var get = await FileManager.setName("12HpBE9mte2YWsEaobQjC5L7yY04rwyqW", "tyur", token);

    console.log(get);

    // var update = await FileManager.setParent("1Vzed5cmsrgraTAqGGw97G3jo6B2bpNoQ","1J2rTKZTf3xT5cOjcmmJebYQadQjzL3EY",token);

    // console.log(update);
    //  var project = await ProjectManager.getProject("TEST2");
    //   var update = await  ProjectManager.editProject(project.projectCode,project.projectNameEn,project.projectNameJp,project.platforms,"WOW");

    //   console.log(update);
    //  var share =   await FileManager.enableShareAnyone("16jtizKbM39Fu9Q1J8U2UNOvfRO_Kd4yH",PermissionRole.writer,token);

    //  console.log(share);

    //  var share =   await FileManager.disableShareAnyone("16jtizKbM39Fu9Q1J8U2UNOvfRO_Kd4yH",token);

    //  console.log(share);

    // var add = await FileManager.addUserAccess("16jtizKbM39Fu9Q1J8U2UNOvfRO_Kd4yH",PermissionRole.writer,"cinowacs@gmail.com",token);

    // console.log(add);

    // var permissionList = await FileManager.getUserPermissions("16jtizKbM39Fu9Q1J8U2UNOvfRO_Kd4yH",token);

    // console.log(permissionList);

    //14978058619349487425

    // var add = await FileManager.addUserAccess("16jtizKbM39Fu9Q1J8U2UNOvfRO_Kd4yH",PermissionRole.writer,"cinowacs@gmail.com",token);

    // console.log(add);

    //await DatabaseManager.getProject("ddd").then(console.log).catch(console.error);

    //await DatabaseManager.getProjectList().then(console.log).catch(console.error);
    //await DatabaseManager.getBuild("Raphael.png","ddds").then(console.log).catch(console.error);

    //05619582326319160433
    // var delele = await FileManager.deleteUserAccess("16jtizKbM39Fu9Q1J8U2UNOvfRO_Kd4yH","anyoneWithLink",token);

    // console.log(delele);
    //await  DatabaseManager.getBuilds("","PWBR");

    // console.log(build);
    //    var projects = await DatabaseManager.getProjectList();
    // console.log(projects);

    //     var project = await DatabaseManager.getProject("eee");
    //  console.log(project);

    //  project.projectNameEn ="eee222";
    //  var edit = await DatabaseManager.editProject(project);
    //  console.log(edit);

    return "testFunctionWithA completed";
}




export const testFunctionWithAAndB = function (a, b) {
    // Add testing

    return "testFunctionWithAAndB completed";
}


