<template>
  <v-data-table
    :headers="headers"
    :items="desserts"
    sort-by="version"
    class="elevation-0"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <!-- <v-toolbar-title>Projects</v-toolbar-title> -->
        <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" width="600px" :persistent="isDisable">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              style="background: #0072BC"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            >
              New Build
            </v-btn>
          </template>
          <v-card :disabled="isDisable">
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <!-- Platform -->
                    <v-row align="center" justify="center">
                      <v-col cols="auto" class="mr-auto py-1">Platform: </v-col>
                      <v-col class="py-1">
                        <v-chip-group
                          v-model="editedItem.edit_platform"
                          active-class="primary--text"
                          mandatory
                        >
                          <v-chip
                              v-for="id in current_platforms"
                            :key="id"
                            @click="updatePlatform(id)"
                            >{{ getPlatformName(id) }}</v-chip>
                        </v-chip-group>
                      </v-col>
                    </v-row>
                    <!-- Target Build -->
                    <v-row align="center" justify="center">
                      <v-col cols="auto" class="mr-auto py-1"
                        >Target Build:
                      </v-col>
                      <v-col class="py-1">
                        <v-chip-group
                          v-model="editedItem.target_build"
                          active-class="primary--text"
                          mandatory
                        >
                          <v-chip
                            v-for="(name, index) in getTargetBuildNames()"
                            :key="index"
                            >{{ name }}</v-chip
                          >
                        </v-chip-group>
                      </v-col>
                    </v-row>
                    <!-- Link Type -->
                    <v-row align="center" justify="center">
                      <v-col cols="auto" class="mr-auto py-1"
                        >Link Type:
                      </v-col>
                      <v-col class="py-1">
                        <v-chip-group
                          v-model="editedItem.link_type"
                          active-class="primary--text"
                          mandatory
                        >
                          <v-chip
                            v-for="(name, index) in getLinkTypeNames()"
                            :key="index"
                            >{{ name }}</v-chip
                          >
                        </v-chip-group>
                      </v-col>
                    </v-row>
                    <!-- File Type (only standalone) -->
                    <v-row align="center" justify="center" v-if="canSelectFolder">
                      <v-col cols="auto" class="mr-auto py-1"
                        >File Type:
                      </v-col>
                      <v-col class="py-1">
                        <v-chip-group
                          v-model="editedItem.file_type"
                          active-class="primary--text"
                          mandatory
                        >
                          <v-chip
                            v-for="(name, index) in getFileTypeNames()"
                            :key="index"
                            >{{ name }}</v-chip
                          >
                        </v-chip-group>
                      </v-col>
                    </v-row>
                    <!-- Build File -->
                    <v-row align="center" justify="center">
                      <v-col cols="auto" class="mr-auto py-1"
                        >Build File:
                      </v-col>
                      <v-col class="py-1">
                        <v-file-input
                          v-if="isFolderInput"
                          v-model="editedItem.build_files"
                          truncate-length="23"
                          :accept="buildFileType"
                          show-size
                          @change="AddNewEvent"
                          prepend-icon="folder"
                          multiple
                          webkitdirectory
                        ></v-file-input>
                        <v-file-input
                          v-else
                          v-model="editedItem.build_files"
                          truncate-length="23"
                          :accept="buildFileType"
                          show-size
                          @change="AddNewEvent"
                          prepend-icon="mdi-file-document"
                        ></v-file-input>
                      </v-col>
                    </v-row>
                    <!-- Version -->
                    <v-row align="center" justify="center">
                      <v-col cols="auto" class="mr-auto py-1">Version: </v-col>
                      <v-col class="py-1">
                        <v-text-field
                          v-model="editedItem.version"
                          @blur="updateReversion"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <!-- File Name -->
                    <v-row align="center" justify="center">
                      <v-col cols="auto" class="mr-auto py-1"
                        >File Name:
                      </v-col>
                      <v-col class="py-1">
                        <v-card-text>
                          {{ buildName }}
                        </v-card-text>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="create"
                :disabled="createCondition"
              >
                {{ formSubmit }}
              </v-btn>
            </v-card-actions>
            <v-progress-linear
              color="primary"
              indeterminate
              rounded
              height="6"
              v-if="isDisable"
            ></v-progress-linear>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline"
              >Are you sure you want to delete this item?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.create_date`]="{ item }">
      {{ item.create_date.toISOString().substring(0, 10) }}
    </template>
    <template v-slot:[`item.target_build`]="{ item }">
      <v-chip
        class="mx-1 white--text"
        close-icon="mdi-close-outline"
        :color="getTargetBuildColor(item.target_build)"
        small
        >{{ getTargetBuildName(item.target_build) }}
      </v-chip>
    </template>
    <template v-slot:[`item.link_type`]="{ item }">
      <v-chip
        class="mx-1 white--text"
        close-icon="mdi-close-outline"
        :color="getLinkTypeColor(item.link_type)"
        small
        >{{ getLinkTypeName(item.link_type) }}
      </v-chip>
    </template>
    <template v-slot:[`item.platform`]="{ item }">
      <v-chip
        class="mx-1 white--text"
        close-icon="mdi-close-outline"
        :color="getPlatformColor(item.platform)"
        small
        >{{ getPlatformName(item.platform) }}
      </v-chip>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-btn
        small
        depressed
        class="mr-2"
        v-on:click="copyLink(item.file_id)"
        >Copy</v-btn>
      <v-btn
        small
        depressed
        class="mr-2"
        :href="getFileLink(item.file_id)"
        target="_blank"
        >Open Drive</v-btn>
    </template>
    <template v-slot:no-data>
      <v-progress-circular indeterminate color="primary" v-if="isLoading"></v-progress-circular>
    </template>
  </v-data-table>
</template>

<script>
import { Platform, TargetBuild, LinkType } from "../../api/entities.js";

export default {
  data: () => ({
    isLoading: true,
    isDisable: false,
    current_code: "",
    current_platforms: [],
    platformsActive: [0],
    dialog: false,
    dialogDelete: false,
    headers: [
      { text: "Version", value: "version" },
      { text: "Date", value: "create_date" },
      { text: "Reversion", value: "reversion", sortable: false },
      { text: "Target Build", value: "target_build", sortable: false },
      { text: "Platform", value: "platform", sortable: false },
      { text: "LinkType", value: "link_type", sortable: false },
      { text: "Action", value: "actions", sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      code: "",
      version: "",
      date_string: "000000",
      create_date: new Date(),
      reversion: "",
      target_build: 0,
      platform: 0,
      file_name: "",
      link_type: 0,
      file_type: 0,
      link: "",
      build_files: [],
      event: undefined,
      extension: "",
      file_id: "",
      edit_platform: 0,
    },
    defaultItem: {
      code: "",
      version: "",
      date_string: "000000",
      create_date: new Date(),
      reversion: "",
      target_build: 0,
      platform: 0,
      file_name: "",
      link_type: 0,
      file_type: 0,
      link: "",
      build_files: [],
      event: undefined,
      extension: "",
      file_id: "",
      edit_platform: 0,
    },
    previews: [],
    errorImage: "url of an image to use to indicate an error",
    files: [],
    isDisable: false,
  }),

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  computed: {
    buildName() {
      return this.editedItem.event != null
        ? `${this.editedItem.code}_${this.getTargetBuildBuildName(
            this.editedItem.target_build
          )}_${this.getPlatformBuildName(
            this.editedItem.platform
          )}_V${this.editedItem.version.replaceAll(".", "_")}_${
            this.editedItem.date_string
          }_${this.editedItem.reversion}${this.editedItem.extension ? '.' + this.editedItem.extension : ''}`
        : "-";
    },
    dateString() {
      return `${this.editedItem.create_date
        .getFullYear()
        .toString()
        .slice(2)}${String(this.editedItem.create_date.getMonth() + 1).padStart(
        2,
        "0"
      )}${String(this.editedItem.create_date.getDate()).padStart(2, "0")}`;
    },
    formSubmit() {
      return this.editedIndex === -1 ? "Create" : "Edit";
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Build" : "Edit Build";
    },
    createCondition() {
      return this.editedItem.event != null &&
        this.editedItem.version
        ? false
        : true;
    },
    buildFileType() {
      switch (this.editedItem.platform) {
        case Platform.iOS:
          return ".IPA";
        case Platform.Android:
          return ".APK";
        case Platform.Standalone:
          switch(this.editedItem.file_type){
            case 0:
              return ".EXE,.ZIP,.RAR";
            case 1:
              return "";
          }
      }
      return "";
    },
    canSelectFolder(){
      return this.editedItem.platform == Platform.Standalone;
    },
    isFolderInput(){
      return this.editedItem.platform == Platform.Standalone && 
            this.editedItem.file_type == 1;
    }
  },

  methods: {
    async initialize() {
      this.current_code = this.$route.params.code;
      this.desserts = await this.$api.getBuildsTable(this.current_code);
      var project = await this.$api.getProject(this.current_code);
      this.current_platforms = project.platforms;
      this.defaultItem.platform = this.current_platforms[0];
      this.editedItem.platform = this.current_platforms[0];
      this.isLoading = false;
    },
    openProject(item) {
      this.router.push({ path: `${item.code}` });
    },
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    updatePlatform(id){
      console.log('updatePlatform: ', id);
      this.editedItem.platform = id;
    },
    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;

      this.$nextTick(() => {
        this.editedItem.event = undefined;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async create() {
      this.editedItem.code = this.current_code;
      this.editedItem.file_name = this.buildName;
      // set name
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        var create_build = {
          code: this.editedItem.code,
          file_name: this.editedItem.file_name,
          target_build: this.editedItem.target_build,
          platform: this.editedItem.platform,
          version: this.editedItem.version,
          file_id: "",
          build_files: this.editedItem,
          link: this.editedItem.link,
          link_type: this.editedItem.link_type,
          create_date: this.editedItem.create_date,
          date_string: this.editedItem.date_name,
          reversion: this.editedItem.reversion,
          event: this.editedItem.event,
        };
        this.isDisable = true;
        var build;
        if(this.editedItem.file_type == 0)
          build = await this.$api.createBuild(create_build);
        else
          build = await this.$api.createBuildWithFolder(create_build);
        this.editedItem.file_id = build.fileId;
        this.isDisable = false;
        this.desserts.push(this.editedItem);
      }
      this.close();
    },
    getPlatformColor(id) {
      switch (id) {
        case Platform.iOS:
          return "primary";
        case Platform.Android:
          return "green";
        case Platform.Standalone:
          return "orange";
        default:
          return "#a6a6a6";
      }
    },
    getTargetBuildColor(id) {
      switch (id) {
        case TargetBuild.Development:
          return "orange";
        case TargetBuild.Production:
          return "primary";
      }
    },
    getLinkTypeColor(id) {
      switch (id) {
        case LinkType.Private:
          return "red";
        case LinkType.Public:
          return "green";
      }
    },
    getTargetBuildName(id) {
      return TargetBuild[id];
    },
    getTargetBuildID(name) {
      return TargetBuild[name];
    },
    getLinkTypeName(id) {
      return LinkType[id];
    },
    getLinkTypeID(name) {
      return LinkType[name];
    },
    getPlatformName(id) {
      return Platform[id];
    },
    getPlatformID(name) {
      return Platform[name];
    },
    getISODate(date) {
      var timezoneOffset = date.getMinutes() + date.getTimezoneOffset();
      var timestamp = date.getTime() + timezoneOffset * 1000;
      var correctDate = new Date(timestamp);

      correctDate.setUTCHours(0, 0, 0, 0);
      return correctDate.toISOString();
    },
    getPlatformNames() {
      return this.getEnumName(Platform);
    },
    getTargetBuildNames() {
      return this.getEnumName(TargetBuild);
    },
    getLinkTypeNames() {
      return this.getEnumName(LinkType);
    },
    getFileTypeNames() {
      return [ "File", "Folder"];
    },
    getTargetBuildBuildName(targetBuild) {
      switch (targetBuild) {
        case TargetBuild.Development:
          return "DEV";
        case TargetBuild.Production:
          return "PRD";
      }
    },
    getPlatformBuildName(platform) {
      switch (platform) {
        case Platform.iOS:
          return "IOS";
        case Platform.Android:
          return "ANDROID";
        case Platform.Standalone:
          return "PC";
      }
    },
    getEnumName(myEnum) {
      var array = [];
      for (var enumMember in myEnum) {
        var isValueProperty = parseInt(enumMember, 10) >= 0;
        if (isValueProperty) {
          array.push(myEnum[enumMember]);
        }
      }
      return array;
    },
    AddNewEvent(event) {
      console.log("add event:", event);
      this.editedItem.code = this.current_code;
      this.editedItem.create_date = new Date();
      this.editedItem.date_string = this.dateString;
      this.editedItem.extension =
        this.editedItem.build_files != null && this.editedItem.build_files.name
          ? event.name.split(".").pop()
          : "";
      this.editedItem.version = this.getLastVersion();
      this.editedItem.event = event;
      this.updateReversion();
    },
    updateReversion(){
      var count = 1;
      var current_date = new Date();
      console.log(this.desserts);
      for(var build of this.desserts){
        console.log("version: ", this.editedItem.version);
        console.log("build date: ", build.create_date.getDate());
        if(build.version == this.editedItem.version){
          if(
            current_date.getFullYear() == build.create_date.getFullYear() &&
            current_date.getMonth() == build.create_date.getMonth() &&
            current_date.getDate() == build.create_date.getDate()
          ) {
            count++;
          }
        }
      }
      this.editedItem.reversion = `RV${count}`;
    },
    getLastVersion(){
      var version = "0.0.0";
      var current_date = new Date(0);

      for(var build of this.desserts){
        if(current_date < build.create_date){
          current_date = build.create_date;
          version = build.version;
        }
      }
      return version;
    },
    getFileLink(file_id) {
      return this.$api.getFileLink(file_id);
    },
    copyLink(file_id) {
      console.log("Start");
      var input = document.createElement("input");
      input.setAttribute("value", this.$api.getFileLink(file_id));
      document.body.appendChild(input);
      input.select();
      var result = document.execCommand("copy");
      document.body.removeChild(input);
      console.log(result);
      return result;
    },
  },
};
</script>