import '@fortawesome/fontawesome-free/css/all.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { colors } from 'vuetify/lib';
Vue.use(Vuetify);

export default new Vuetify({
    icons:{
        iconfont: 'md' || 'fa'
    },
    theme: {
        themes:{
            light: {
                background: colors.grey.lighten5,
            },
            dark: {
                background: colors.grey.darken4,
            }
        }
    }
});
