import Vue from 'vue'
import VueRouter from 'vue-router'
import Overview from '../views/Overview.vue'
import Projects from '../views/Projects.vue'
import Project from '../views/Project.vue'
import APITest from '../views/APITest.vue'
import Login from '../Login.vue'
import api from '../api'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Overview',
        component: Overview,
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/projects',
        name: 'Projects',
        component: Projects,
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/projects/:code',
        name: 'Project',
        component: Project,
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/api-test',
        name: 'API Test',
        component: APITest
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


export default router
