<template>
  <v-app>
    <v-main style="background: #0072BC">
      <v-container fill-height="fill-height">
        <v-layout align-center="align-center" justify-center="justify-center">
          <v-flex class="login-form text-xs-center">
            <v-card-actions class="justify-center flex-row">
              <v-img
                class="justify-center mr-7 ml-7"
                :aspect-ratio="1280 / 356"
                src="proudia.png"
              ></v-img>
            </v-card-actions>
            <v-card-actions class="justify-center flex-row">
              <v-btn class="ma-2 pa-4" large @click="login">
                <img
                  src="https://www.iconfinder.com/data/icons/social-media-2210/24/Google-512.png"
                  style="width: 30px; padding-right: 10px"
                  alt=""
                  class="justify-center"
                  align-center="align-center"
                  justify-center="justify-center"
                />
                Login with Gmail
              </v-btn>
            </v-card-actions>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
    <v-footer app="app">
      <v-flex class="text-xs-center">© 2018. All rights reserved.</v-flex>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "Login",

  data() {
    return {
      user: {
        email: "",
        password: "",
      },
      options: {
        isLoggingIn: true,
      },
    };
  },
  methods: {
    login() {
      this.$api.login().then(_ => {
        if(this.$api.userdata.email == this.$api.default_email)
          this.$router.push({ path: this.$api.default_path });
        else
          this.$api.logout();
      });
    },
  },
};
</script>


<style scoped>
.login-form {
  max-width: 500px;
}
</style>
