<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.background }">
    <v-container fluid fill-width>
      <v-layout row class="ma-8" child-flex>
        <v-card outlined class="pa-2" elevation="1">
          <v-tabs>
            <v-tab>Builds</v-tab>
            <v-tab>Sources</v-tab>
            <v-tab>General</v-tab>

            <v-tab-item v-for="n in 3" :key="n">
              <Builds v-if="n === 1"/>
              <Sources v-if="n === 2"/>
              <General v-if="n === 3"/>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>

import Builds from './ProjectTap/Builds'
import General from './ProjectTap/General'
import Sources from './ProjectTap/Sources'

export default {
  name: "project",
  data: () => ({}),
  components: {
      Builds,
      General,
      Sources
  }
};
</script>