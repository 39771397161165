<template>
    <v-app :style="{ background: $vuetify.theme.themes.light.background }">
        <v-container fluid fill-width>
            <v-layout row class="ma-8" child-flex>
                <v-card outlined class="pa-2" elevation="1">
                    <v-row>
                        <v-col
                            ><div id="chart">
                                <apexchart
                                    ref="realtimeChart"
                                    type="donut"
                                    :options="projectPieOptions"
                                    :series="projectPieSeries"
                                    width="500px"
                                    v-if="
                                        projectPieSeries &&
                                        projectPieSeries.length
                                    "
                                ></apexchart>
                            </div>
                        </v-col>

                        <v-col>
                            <div id="chart">
                                <apexchart
                                    type="bar"
                                    height="380"
                                    :options="assetsBarOptions"
                                    :series="assetsBarSeries"
                                    v-if="
                                        assetsBarSeries &&
                                        assetsBarSeries[0].data.length
                                    "
                                ></apexchart>
                            </div>
                        </v-col>
                    </v-row>
                    <v-data-table
                        :headers="headers"
                        :items="desserts"
                        sort-by="calories"
                        class="elevation-0"
                    >
                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-toolbar-title
                                    >Recently Uploaded TEST</v-toolbar-title
                                >
                                <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
                                <v-spacer></v-spacer>
                            </v-toolbar>
                        </template>
                        <template v-slot:[`item.actions`]="{ }">
                            <v-btn
                                small
                                depressed
                                class="mr-2"
                                >Copy</v-btn
                            >
                            <v-btn
                                small
                                depressed
                                class="mr-2"
                                >Open Drive</v-btn
                            >
                        </template>
                    </v-data-table>
                </v-card>
            </v-layout>
        </v-container>
    </v-app>
</template>
        </v-card>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
    components: {
        apexchart: VueApexCharts,
    },
    data: () => ({
        projectPieSeries: [],
        assetsBarSeries: [
            {
                data: [],
            },
        ],
        projectPieOptions: {
            labels: [],
            chart: {
                type: "donut",
                fontFamily: "Arial, Helvetica, sans-serif",
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200,
                        },
                        legend: {
                            position: "bottom",
                        },
                    },
                },
            ],
            title: {
                text: "Storage Summary",
                align: "center",
            },
            animations: {
                enabled: true,
                easing: "easeinout",
                speed: 800,
                animateGradually: {
                    enabled: true,
                    delay: 150,
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 350,
                },
            },
        },
        assetsBarOptions: {
            chart: {
                type: "bar",
                height: 380,
                fontFamily: "Helvetica, Arial, sans-serif",
            },
            plotOptions: {
                bar: {
                    barHeight: "100%",
                    distributed: true,
                    horizontal: true,
                    dataLabels: {
                        position: "bottom",
                    },
                },
            },
            dataLabels: {
                enabled: true,
                textAnchor: "start",
                style: {
                    colors: ["#fff"],
                },
                formatter: function (val, opt) {
                    return (
                        opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
                    );
                },
                offsetX: 0,
                dropShadow: {
                    enabled: true,
                },
            },
            stroke: {
                width: 1,
                colors: ["#fff"],
            },
            xaxis: {
                categories: [],
            },
            yaxis: {
                labels: {
                    show: false,
                },
            },
            title: {
                text: "Storage Summary by Type",
                align: "center",
                floating: true,
            },
            // subtitle: {
            //     text: "Category Names as DataLabels inside bars",
            //     align: "center",
            // },
            tooltip: {
                theme: "dark",
                x: {
                    show: false,
                },
                y: {
                    title: {
                        formatter: function () {
                            return "";
                        },
                    },
                },
            },
        },
        headers: [
            { text: "Code", value: "code" },
            { text: "Name", value: "name" },
            { text: "Create At", value: "create_at", sortable: false },
            { text: "Actions", value: "actions", sortable: false },
        ],
        desserts: [],
    }),
    created() {
        this.initialize();
    },
    methods: {
        async initialize() {
            // Update Storage Summary
            var storageSummary = await this.$api.getStorageSummary();

            for (var serie of storageSummary.series)
                this.projectPieSeries.push(serie);

            for (var label of storageSummary.labels)
                this.projectPieOptions.labels.push(label);

            // Update Storage Summary by Type
            var storageSummaryByType = await this.$api.getStorageSummaryByType();

            for (var serie of storageSummaryByType.series)
                this.assetsBarSeries[0].data.push(serie);

            for (var label of storageSummaryByType.labels)
                this.assetsBarOptions.xaxis.categories.push(label);

            // Update desserts
            this.desserts = await this.$api.getRecentlyUploaded();
        },
    },
    setup() {},
};
</script>

<style scoped>
.rounded {
    border-radius: 30px;
}
.ligne {
    border-right: solid 1px #95a5a6;
}
</style>