"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileResponse = void 0;
var projectmanager_1 = require("./projectmanager");
var FileManager = /** @class */ (function () {
    function FileManager() {
    }
    FileManager.arrayBufferToBase64 = function (buffer) {
        var binary = '';
        var bytes = new Uint8Array(buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return btoa(binary);
    };
    FileManager.API_FILES = "https://www.googleapis.com/drive/v3/files";
    FileManager.API_UPLOAD_FILES = "https://www.googleapis.com/upload/drive/v3/files";
    FileManager.createFolder = function (folderName, folderParentId, accessToken) { return __awaiter(void 0, void 0, void 0, function () {
        var postData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if ((!accessToken || accessToken.length === 0)) {
                        throw new Error('Error: require access token');
                    }
                    postData = {
                        mimeType: "application/vnd.google-apps.folder",
                        name: folderName,
                        parents: (!folderParentId || folderParentId.length === 0) ? "" : [folderParentId],
                    };
                    return [4 /*yield*/, fetch(FileManager.API_FILES, {
                            method: "POST",
                            headers: { "Content-Type": "application/json", "Authorization": "Bearer " + accessToken },
                            body: JSON.stringify(postData),
                        })
                            .then(function (res) {
                            if (!res.ok) {
                                throw new Error('Error: ' + res.status + ' ' + res.statusText);
                            }
                            return res.clone().json();
                        }).then(function (json) {
                            return new FileResponse(json.id, json.name, json.mimeType);
                        })
                            .catch(function (error) {
                            throw new Error('Error: ' + error);
                        })];
                case 1: 
                //    console.log(postData);
                return [2 /*return*/, _a.sent()];
            }
        });
    }); };
    FileManager.getFolder = function (folderName, folderParentId, accessToken) {
        return __awaiter(this, void 0, void 0, function () {
            var query;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if ((!accessToken || accessToken.length === 0)) {
                            throw new Error('Error: require access token');
                        }
                        query = "mimeType='application/vnd.google-apps.folder' and trashed = false and name='" + folderName + "'";
                        if (folderParentId || folderParentId.length !== 0) {
                            query += " and '" + folderParentId + "' in parents";
                        }
                        return [4 /*yield*/, fetch(FileManager.API_FILES + "?q=" + query, {
                                method: "GET",
                                headers: { "Content-Type": "application/json", "Authorization": "Bearer " + accessToken }
                            })
                                .then(function (res) {
                                if (!res.ok) {
                                    throw new Error('Error: ' + res.status + ' ' + res.statusText);
                                    return null;
                                }
                                return res.clone().json();
                            }).then(function (json) {
                                if (json.files.length > 0) {
                                    return new FileResponse(json.files[0].id, json.files[0].name, json.files[0].mimeType);
                                }
                                else {
                                    return null;
                                }
                            })
                                .catch(function (error) {
                                throw new Error('Error: ' + error);
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    FileManager.getFolderList = function (folderParentId, accessToken) {
        return __awaiter(this, void 0, void 0, function () {
            var query;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if ((!accessToken || accessToken.length === 0)) {
                            throw new Error('Error: require access token');
                        }
                        query = "mimeType='application/vnd.google-apps.folder' and trashed = false and '" + folderParentId + "' in parents";
                        return [4 /*yield*/, fetch(FileManager.API_FILES + "?q=" + query, {
                                method: "GET",
                                headers: { "Content-Type": "application/json", "Authorization": "Bearer " + accessToken }
                            })
                                .then(function (res) {
                                if (!res.ok) {
                                    throw new Error('Error:' + res.status + ' ' + res.statusText);
                                }
                                return res.clone().json();
                            }).then(function (json) {
                                if (json.files.length > 0) {
                                    var list = new Array();
                                    for (var index = 0; index < json.files.length; index++) {
                                        var folder = json.files[index];
                                        list.push(new FileResponse(folder.id, folder.name, folder.mimeType));
                                    }
                                    return list;
                                }
                                else {
                                    return [];
                                }
                            })
                                .catch(function (error) {
                                console.error('Error:', error);
                                return null;
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    FileManager.getFileList = function (folderParentId, accessToken) {
        return __awaiter(this, void 0, void 0, function () {
            var query;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if ((!accessToken || accessToken.length === 0)) {
                            throw new Error('Error: require access token');
                        }
                        query = "trashed = false and '" + folderParentId + "' in parents";
                        return [4 /*yield*/, fetch(FileManager.API_FILES + "?q=" + query + "&fields=*", {
                                method: "GET",
                                headers: { "Content-Type": "application/json", "Authorization": "Bearer " + accessToken }
                            })
                                .then(function (res) {
                                if (!res.ok) {
                                    throw new Error('Error:' + res.status + ' ' + res.statusText);
                                }
                                return res.clone().json();
                            }).then(function (json) {
                                //  console.log(json);
                                if (json.files.length > 0) {
                                    var list = new Array();
                                    for (var index = 0; index < json.files.length; index++) {
                                        var file = json.files[index];
                                        var isPublic = file.permissionIds.find(function (element) { return element === 'anyoneWithLink'; }) ? true : false;
                                        list.push(new FileResponse(file.id, file.name, file.mimeType, isPublic));
                                    }
                                    return list;
                                }
                                else {
                                    return [];
                                }
                            })
                                .catch(function (error) {
                                console.error('Error:', error);
                                return null;
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    FileManager.uploadFile = function (fileName, mimeType, data, folderParentId, accessToken) {
        return __awaiter(this, void 0, void 0, function () {
            var boundary, delimiter, close_delim, metadata, base64Data, multipartRequestBody;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!accessToken || accessToken.length === 0) {
                            throw new Error('Error: require access token');
                        }
                        if (!mimeType || mimeType.length === 0) {
                            throw new Error('Error: require mimeType');
                        }
                        if (!fileName || fileName.length === 0) {
                            throw new Error('Error: require fileName');
                        }
                        if (!data) {
                            throw new Error('Error: require file data');
                        }
                        boundary = "foo_bar_baz";
                        delimiter = "\r\n--" + boundary + "\r\n";
                        close_delim = "\r\n--" + boundary + "--";
                        metadata = {
                            name: fileName,
                            mimeType: mimeType,
                            parents: (!folderParentId || folderParentId.length === 0) ? "" : [folderParentId],
                        };
                        base64Data = FileManager.arrayBufferToBase64(data);
                        multipartRequestBody = delimiter + 'Content-Type: application/json\r\n\r\n' +
                            JSON.stringify(metadata) +
                            delimiter + 'Content-Type: ' + mimeType + '\r\n' +
                            'Content-Transfer-Encoding: base64\r\n' + '\r\n' +
                            base64Data + close_delim;
                        return [4 /*yield*/, fetch(FileManager.API_UPLOAD_FILES + "?uploadType=multipart", {
                                method: "POST",
                                headers: { "Content-Type": "multipart/form-data; boundary=" + boundary, "Authorization": "Bearer " + accessToken },
                                body: multipartRequestBody,
                            })
                                .then(function (res) {
                                if (!res.ok) {
                                    throw new Error('Error1: ' + res.status + ' ' + res.statusText);
                                }
                                return res.clone().json();
                            }).then(function (json) {
                                return new FileResponse(json.id, json.name, json.mimeType);
                            })
                                .catch(function (error) {
                                throw new Error('Error: ' + error);
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    FileManager.setName = function (id, name, accessToken) { return __awaiter(void 0, void 0, void 0, function () {
        var postData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!accessToken || accessToken.length === 0) {
                        throw new Error('Error: require access token');
                    }
                    if (!id || id.length === 0) {
                        throw new Error('Error: require fileId or folderId');
                    }
                    if (!name || name.length === 0) {
                        throw new Error('Error: require name');
                    }
                    postData = {
                        name: name
                    };
                    return [4 /*yield*/, fetch(FileManager.API_FILES + "/" + id, {
                            method: "PATCH",
                            headers: { "Content-Type": "application/json", "Authorization": "Bearer " + accessToken },
                            body: JSON.stringify(postData)
                        })
                            .then(function (res) {
                            if (!res.ok) {
                                throw new Error('Error:' + res.status + ' ' + res.statusText);
                            }
                            return res.clone().json();
                        }).then(function (json) {
                            return new FileResponse(json.id, json.name, json.mimeType);
                        })
                            .catch(function (error) {
                            console.error('Error:', error);
                            return null;
                        })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    }); };
    FileManager.setParent = function (id, parentId, accessToken) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!accessToken || accessToken.length === 0) {
                        throw new Error('Error: require access token');
                    }
                    if (!id || id.length === 0) {
                        throw new Error('Error: require fileId or folderId');
                    }
                    if (!parentId || parentId.length === 0) {
                        throw new Error('Error: require parentId');
                    }
                    return [4 /*yield*/, fetch(FileManager.API_FILES + "/" + id + "?addParents=" + parentId, {
                            method: "PATCH",
                            headers: { "Content-Type": "application/json", "Authorization": "Bearer " + accessToken }
                        })
                            .then(function (res) {
                            if (!res.ok) {
                                throw new Error('Error:' + res.status + ' ' + res.statusText);
                            }
                            return res.clone().json();
                        }).then(function (json) {
                            return new FileResponse(json.id, json.name, json.mimeType);
                        })
                            .catch(function (error) {
                            console.error('Error:', error);
                            return null;
                        })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    }); };
    FileManager.getUserPermissions = function (fileId, accessToken) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!fileId || fileId.length === 0) {
                        throw new Error("Error: Require fileId");
                    }
                    if ((!accessToken || accessToken.length === 0)) {
                        throw new Error('Error: Require access token');
                    }
                    return [4 /*yield*/, fetch(FileManager.API_FILES + "/" + fileId + "/permissions/?fields=*", {
                            method: "GET",
                            headers: { "Content-Type": "application/json", "Authorization": "Bearer " + accessToken },
                        })
                            .then(function (res) {
                            if (!res.ok) {
                                throw new Error('Error1: ' + res.status + ' ' + res.statusText);
                            }
                            return res.clone().json();
                        }).then(function (json) {
                            var users = new Array();
                            console.log(json);
                            for (var index = 0; index < json.permissions.length; index++) {
                                var data = json.permissions[index];
                                var user = new projectmanager_1.UserPermission();
                                user.emailAddress = data.emailAddress;
                                user.name = data.displayName;
                                user.role = data.role;
                                user.id = data.id;
                                user.type = data.type;
                                user.photoLink = data.photoLink;
                                users.push(user);
                            }
                            return users;
                        })
                            .catch(function (error) {
                            throw new Error('Error2: ' + error);
                        })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    }); };
    FileManager.addUserAccess = function (fileId, role, email, accessToken) { return __awaiter(void 0, void 0, void 0, function () {
        var postData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!fileId || fileId.length === 0) {
                        throw new Error("Error: Require fileId");
                    }
                    if ((!accessToken || accessToken.length === 0)) {
                        throw new Error('Error: Require access token');
                    }
                    if (!email || email.length === 0) {
                        throw new Error("Error: Require email");
                    }
                    postData = {
                        type: projectmanager_1.PermissionType[projectmanager_1.PermissionType.user],
                        role: projectmanager_1.PermissionRole[role],
                        emailAddress: email
                    };
                    return [4 /*yield*/, fetch(FileManager.API_FILES + "/" + fileId + "/permissions", {
                            method: "POST",
                            headers: { "Content-Type": "application/json", "Authorization": "Bearer " + accessToken },
                            body: JSON.stringify(postData),
                        })
                            .then(function (res) {
                            if (!res.ok) {
                                throw new Error('Error1: ' + res.status + ' ' + res.statusText);
                            }
                            return res.clone().json();
                        })
                            .catch(function (error) {
                            throw new Error('Error2: ' + error);
                        })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    }); };
    FileManager.deleteUserAccess = function (fileId, permissionId, accessToken) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!fileId || fileId.length === 0) {
                        throw new Error("Error: Require fileId");
                    }
                    if ((!permissionId || permissionId.length === 0)) {
                        throw new Error('Error: require permissionId');
                    }
                    if ((!accessToken || accessToken.length === 0)) {
                        throw new Error('Error: require accessToken');
                    }
                    return [4 /*yield*/, fetch(FileManager.API_FILES + "/" + fileId + "/permissions/" + permissionId, {
                            method: "DELETE",
                            headers: { "Content-Type": "application/json", "Authorization": "Bearer " + accessToken }
                        })
                            .then(function (res) {
                            if (!res.ok) {
                                throw new Error('Error: ' + res.status + ' ' + res.statusText);
                            }
                            return;
                        })
                            .catch(function (error) {
                            throw new Error('Error: ' + error);
                        })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    }); };
    FileManager.enableShareAnyone = function (fileId, role, accessToken) {
        return __awaiter(this, void 0, void 0, function () {
            var postData, url, method;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!fileId || fileId.length === 0) {
                            throw new Error("Error: Require fileId");
                        }
                        if ((!accessToken || accessToken.length === 0)) {
                            throw new Error('Error: require accessToken');
                        }
                        postData = {
                            type: projectmanager_1.PermissionType[projectmanager_1.PermissionType.anyone],
                            role: projectmanager_1.PermissionRole[role]
                        };
                        url = FileManager.API_FILES + "/" + fileId + "/permissions";
                        method = "POST";
                        return [4 /*yield*/, fetch(url, {
                                method: method,
                                headers: { "Content-Type": "application/json", "Authorization": "Bearer " + accessToken },
                                body: JSON.stringify(postData),
                            })
                                .then(function (res) {
                                if (!res.ok) {
                                    throw new Error('Error1: ' + res.status + ' ' + res.statusText);
                                }
                                return res.clone().json();
                            })
                                .catch(function (error) {
                                throw new Error('Error2: ' + error);
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    FileManager.disableShareAnyone = function (fileId, accessToken) {
        return __awaiter(this, void 0, void 0, function () {
            var url, method;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!fileId || fileId.length === 0) {
                            throw new Error("Error: Require fileId");
                        }
                        if ((!accessToken || accessToken.length === 0)) {
                            throw new Error('Error: require accessToken');
                        }
                        url = FileManager.API_FILES + "/" + fileId + "/permissions/anyoneWithLink";
                        method = "DELETE";
                        return [4 /*yield*/, fetch(url, {
                                method: method,
                                headers: { "Content-Type": "application/json", "Authorization": "Bearer " + accessToken },
                            })
                                .then(function (res) {
                                if (!res.ok) {
                                    throw new Error('Error1: ' + res.status + ' ' + res.statusText);
                                }
                                return;
                            })
                                .catch(function (error) {
                                throw new Error('Error2: ' + error);
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    FileManager.getFileLink = function (fileId) {
        if (!fileId || fileId.length === 0) {
            throw new Error("Error: Require fileId");
        }
        return "https://drive.google.com/file/d/" + fileId;
    };
    FileManager.getFolderLink = function (folderId) {
        if (!folderId || folderId.length === 0) {
            throw new Error("Error: Require fileId");
        }
        return "https://drive.google.com/drive/u/3/folders/" + folderId;
    };
    return FileManager;
}());
exports.default = FileManager;
var FileResponse = /** @class */ (function () {
    function FileResponse(id, name, mimeType, isPublic) {
        this.isPublic = false;
        this.id = id;
        this.name = name;
        this.mimeType = mimeType;
        if (isPublic !== undefined)
            this.isPublic = isPublic;
    }
    return FileResponse;
}());
exports.FileResponse = FileResponse;
