import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        snackbar: {
            message: "",
            visible: false,
            timeout: 2000,
            type: "",
            color: "",
            title: "",
            icon: "",
            position: "",
            mode: ""
        },
    },
    getters: {

    },
    actions: {
        notifyMessage(state, setting){
            console.log(state);
            state.state.snackbar.message = setting.message
            state.state.snackbar.type = setting.type
            state.state.snackbar.timeout = setting.timeout || 2000
            state.state.snackbar.visible = true
            switch(setting.type){
                case "info":
                    state.state.snackbar.color ="info";
                    state.state.snackbar.icon ="info";
                    state.state.snackbar.mode ="multi-line";
                    state.state.snackbar.position ="top";
                    state.state.snackbar.title = "Information";
                    break;
                case "error":
                    state.state.snackbar.color ="error";
                    state.state.snackbar.icon ="error";
                    state.state.snackbar.mode ="multi-line";
                    state.state.snackbar.position ="top";
                    state.state.snackbar.title = "Error";
                    break;
                case "success":
                    state.state.snackbar.color ="success";
                    state.state.snackbar.icon ="check_circle";
                    state.state.snackbar.mode ="multi-line";
                    state.state.snackbar.position ="top";
                    state.state.snackbar.title = "Success";
                    break;
                case "warning":
                    state.state.snackbar.color ="warning";
                    state.state.snackbar.icon ="warning";
                    state.state.snackbar.mode ="multi-line";
                    state.state.snackbar.position ="top";
                    state.state.snackbar.title = "Warning";
                    break;
            }
            console.log(state.state.snackbar);
        }
    }
})