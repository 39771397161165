import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import "./plugins/gapi";
import router from './router'
import api from './api'
import store from './plugins/vuex'

Vue.config.productionTip = false
Vue.prototype.$api = api

const vueInstance = new Vue({
    router,
    vuetify,
    store,
    render: function (h) { return h(App) }
});

export default {
    vueInstance: vueInstance
}

router.beforeEach(async (to, from, next) => {
    if (to.meta.requiresAuth) {
        var isLogedIn = await Vue.prototype.$api.isLogedIn();
        if (!isLogedIn) {
            next({ name: 'Login' })
        } else {
            next() // go to wherever I'm going
        }
    } else {
        next() // does not require auth, make sure to always call next()!
    }
})
vueInstance.$mount('#app');

